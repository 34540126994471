<template lang="pug">
v-card.user-settings
  v-scrollable(:bottom-offset="20")
    v-card-content
      .wrapper
        h2.title {{ 'pages.profile.general' | translate }}
        p.subtitle
        .row
          .col-md-6
            .form-group
              .col-wrapper
                .col-item
                  .col-item--header
                    label.subtitle {{ 'pages.settings.new_badge_notification' | translate }}
                    v-info-icon(
                      :text="'infotext.new_badge_notification_info' | translate"
                    )
                  v-switch(
                    icon,
                    noMargin,
                    outline,
                    :label="'pages.settings.enabled' | translate",
                    label-position="left",
                    :value="isEnabledNotification",
                    @input="changeBadgeNotifications"
                  )
            .form-group
                .col-item
                  v-warning-info(:text="$t('infotext.new_badge_notification_warning_info')")
            .form-group
                .col-item
                  v-switch(
                    icon,
                    noMargin,
                    outline,
                    :label="$t('infotext.decline_badge_valid_only_with_comment')",
                    label-position="left",
                    :value="declineBadgeValidationsOnlyWithComment",
                    @input="updateValidationsWithComment"
                  )

</template>

  <script>
import { mapActions, mapGetters } from 'vuex'
import vWarningInfo from '@/components/ui/v-warning-info/index.vue'

export default {
  name: 'GeneralSetting',
  components: {
    vWarningInfo
  },

  data: (vm) => ({
    loaded: false
  }),

  computed: {
    ...mapGetters('settings', ['isEnabledNotification', 'declineBadgeValidationsOnlyWithComment'])
  },

  methods: {
    ...mapActions('settings', ['changeBadgeNotifications', 'updateValidationsWithComment'])
  }
}
</script>

  <style lang="scss" scoped>
.col-item{
  display: flex;
  flex-direction: column;
  gap:12px;
  &--header{
    display: flex;
    align-items: center;
    gap: 16px;
  }
  ::v-deep .v-switch__label{
    color: $body-text;
    font-weight: 400;
  }
  .subtitle{
    margin: 0;
  }
}
</style>
