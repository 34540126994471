<template lang="pug">
.v-warning-info(:class="[blockType]")
    icon-triangle-attention.v-warning-info__icon(:style="{color:colorIcon}")
    span.v-warning-info__text {{ text }}
</template>
<script>

import IconTriangleAttention from '@/components/svg/icons/IconTriangleAttention'
const typeList = {
    'warning': '#F68A37',
    'danger': '#F85061',
    'danger-border': '#F85061'
}
export default {
    names: 'v-warning-info',
    components: { IconTriangleAttention },
    props: {
        text: {
            type: String,
            default: ''
        },
        blockType: {
            type: String,
            default: 'warning'
        }
    },
    computed: {
        colorIcon () {
            return typeList[this.blockType]
        }
    }
}
</script>
<style lang="scss" scoped>
$size-icon: 32px;

.v-warning-info {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-radius: 4px;

    &__icon {
        min-width: $size-icon;
        max-width: $size-icon;
        height: $size-icon;
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
    }

    &.warning {
        background: $website-color;
        .v-warning-info__text {
            color: $body-text;
        }
    }

    &.danger {
        background: $secondary-light-red;
        .v-warning-info__text {
            color: $h2-title;
        }
    }

    &.danger-border {
        background: $color-white;
        border: 1px solid $color-error;
        .v-warning-info__text {
            color: $body-text;
        }
    }
}
</style>
