<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.01247 28.8H29.9875C31.5798 28.8 32.5394 27.0386 31.6744 25.7036L17.6868 4.11637C16.8951 2.89448 15.1049 2.89448 14.3132 4.11637L0.325634 25.7036C-0.53937 27.0386 0.420196 28.8 2.01247 28.8Z"
            fill="currentColor" />
        <rect opacity="0.01" x="7.19995" y="9.19971" width="17.6" height="17.6" fill="white" />
        <circle cx="16" cy="23.0592" r="1.98" fill="white" />
        <path d="M15.4931 20.3097H15.9999L15.9999 10.9597C12.3382 10.9597 13.7038 15.4697 15.4931 20.3097Z"
            fill="white" />
        <path d="M16.5069 20.3097H16.0001L16.0001 10.9597C19.6618 10.9597 18.2962 15.4697 16.5069 20.3097Z"
            fill="white" />
    </svg>
</template>

<script>
export default {
    name: 'IconTriangleAttention'
}
</script>
